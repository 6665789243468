// https://medium.com/front-end-hacking/es6-map-vs-object-what-and-when-b80621932373
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
import { uuid as UUIDKey } from '../Models/Enums/modelFields';


class ElementCache {
  constructor(model, maxSize = 1000) {
    this.model = model;
    this.maxSize = maxSize;
    this.map = new Map();
    this.pendingElements = new Map();
  }
  async getElementByUUID(uuid) {
    if (typeof uuid !== typeof '') {
      console.log('Error, in .getElement, uuid is not a string', uuid);
      return null;
    }
    if (this.map.has(uuid)) {
      // console.log('In Element Cache, already have element');
      const element = await this.map.get(uuid);
      this.map.delete(uuid);
      this.map.set(uuid, element);
      return element;
    }
    const element = await this.model.getOne(uuid);
    // console.log('In Element Cache, ..... gettting new element');
    if (element !== null) {
      this.map.delete(uuid);
      this.map.set(uuid, element);
      this.checkMapSize();
      return element;
    }
    return null;
  }
  async updateElement(element) {
    if (element.modelName !== this.model.modelName) {
      console.log('Error. In ElementCache.updateELement for ', this.model.ModelName, 'Supplied', element.modelName);
      return null;
    }
    const { uuid } = element[UUIDKey];
    if (this.pendingElements.has(uuid)) {
      console.log('In updateElement, have pending operation for ', uuid);
      return this.pendingElements[uuid];
    }
    const result = await element.updateSelf();
    this.pendingElements.set(uuid, element);
    if (result !== null) {
      const { uuid } = result;
      if (typeof uuid === typeof '') {
        this.map.delete(uuid);
        this.map.set(uuid, result);
        this.checkMapSize();
      }
    }
    this.pendingElements.delete(uuid);
    return result;
  }
  async deleteElementByUUID(uuid) {
    if (typeof uuid !== typeof '') {
      console.log('In .deleteElementByUUID, uuid is not a string', uuid);
      return null;
    }
    if (this.pendingElements.has(uuid)) {
      console.log('In updateElement, have pending operation for ', uuid);
      return this.pendingElements[uuid];
    }
    this.pendingElements.set(uuid, null);
    const selector = { uuid };
    const result = this.model.remove(selector);
    this.map.delete(uuid);
    this.pendingElements.delete(uuid);
    return result;
  }
  checkMapSize() {
    if (this.map.size > this.maxSize) {
      const keys = this.map.keys();
      if (keys.length > 0) { this.map.delete(keys[0]); }
      this.checkMapSize();
    }
  }
}

export default ElementCache;
