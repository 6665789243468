export const Interest = 'Interest';
export const PublicProfile = 'PublicProfile';
export const PrivateProfile = 'PrivateProfile';
export const Domain = 'Domain';
export const Author = 'Author';
export const Article = 'Article';
export const Comment = 'Comment';
export const BaseModel = 'BaseModel';

const modelTypes = {
  [Interest.toLowerCase()]: Interest.toLowerCase(),
  [PublicProfile.toLowerCase()]: PublicProfile.toLowerCase(),
  [PrivateProfile.toLowerCase()]: PrivateProfile.toLowerCase(),
  [Domain.toLowerCase()]: Domain,
  [Article.toLowerCase()]: Article,
  [Comment.toLowerCase()]: Comment,
  [Author.toLowerCase()]: Author,
};

export default modelTypes;
