const uuid = 'uuid';
const createdAt = 'createdAt';
const updatedAt = 'updatedAt';
const handle = 'handle';

const id = '_id'; // ObjectId
const creatorUuid = 'creatorUuid';
const domainUuid = 'domainUuid';
const parentUuid = 'parentUuid';
const modelType = 'modelType';
const deleted = 'deleted';
const description = 'description';
const title = 'title';
const special = 'special';
const visibility = 'visibility';
const typeName = '__typename';
const rootKey = 'root';
const activeDomain = 'activeDomain';
const email = 'email';


const ModelFields = {
  email,
  uuid,
  createdAt,
  updatedAt,
  creatorUuid,
  handle,
  domainUuid,
  id,
  parentUuid,
  modelType,
  deleted,
  description,
  title,
  special,
  visibility,
  typeName,
  activeDomain,
  rootKey, // Domain
  objectIdFields: [id],
  dateFields: [createdAt, updatedAt],
};

module.exports = ModelFields;
