module.exports = {
  eq: '$eq',
  ne: '$ne',
  lt: '$lt',
  lte: '$lte',
  gt: '$gt',
  gte: '$gte',
  in: '$in',
  sort: '$sort',
  skip: 'skip',
  limit: 'limit',
  asc: 1,
  desc: -1,
};
