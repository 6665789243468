import { API } from 'aws-amplify';

export const commands = {
  list: 'list',
  get: 'get',
  create: 'create',
  update: 'update',
  remove: 'remove',
}

const DEFAULT_SERVER = 'notes';
const DEFAULT_BASE = 'basic';

export default async function(info, server = DEFAULT_SERVER, base = DEFAULT_BASE) {
  const { list, get, create, update, remove } = commands;
  let { command, doc, modelName, uuid, selector, projection, options } = info;
  selector = selector || {};
  projection = projection || {};
  options = options || {};
  if (modelName === null) { throw new Error('in queries, no model provided'); }
  if (command === null) { throw new Error('in queries, no commandn provided'); }
  const basePath = '/' + base + '/' + modelName.toLowerCase();
  let result = null;
  switch (command) {
    case list:
      result = await getList({selector, projection, options, basePath, server});
      return result;
    case get:
      result = await getOne({uuid, selector, projection, options, basePath, server});
      return result;
    case create:
      result = await createOne({doc, options, basePath, server});
      return result;
    case update:
      result = await updateOne({doc, selector, projection, options, basePath, server});
      return result;
    case remove:
      result = await removeOne({selector, projection, options, basePath, server});
      return result;
    default:
      throw new Error('In queries, invalid command');
  }
}
async function getList(incoming) {

  const { server, basePath, options, selector } = incoming
  console.log('In getList', server, basePath, options, selector);
  try {
    const list = await API.get(server, basePath, {
      queryStringParameters: {
        selector: JSON.stringify(selector),
        options: JSON.stringify(options),
      },
    });
    return list;
  } catch (error) {
    throw error;
  }
}
async function getOne(incoming) {
  const { server, basePath, options, projection, uuid } = incoming
  try {
    if (typeof uuid !== typeof '') {
      throw new Error('in queries, invalid uuid');
    }
    const getPath = basePath + '/' + uuid;
    const result = await API.get(server, getPath, {
      queryStringParameters: {
        projection: JSON.stringify(projection),
        options: JSON.stringify(options),
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
}
async function createOne(incoming) {
  const { doc, server, basePath, options, projection } = incoming
  try {
    if (typeof doc !== typeof {}) { throw new Error('in queries, invalid doc'); }
    if (Object.keys(doc).length === 0) { throw new Error('in queries create. empty doc'); }
    const result = await API.post(server, basePath, {
      body: { doc, projection, options },
    });
    return result;
  } catch (error) {
    throw error;
  }
}
async function updateOne(incoming) {
  const { doc, server, basePath, options, projection, selector } = incoming
  try {
    if (typeof doc !== typeof {}) { throw new Error('in queries update, invalid doc'); }
    if (Object.keys(doc).length === 0) { throw new Error('in queries update. empty doc'); }
    const result = await API.put(server, basePath, {
      body: { doc, projection, options, selector },
    });
    if (Object.keys(selector).length === 0) { throw new Error('in queries update, empty selector'); }
    return result;
  } catch (error) {
    throw error;
  }
}
async function removeOne(incoming) {
  const { server, basePath, options, projection, selector } = incoming
  try {
    if (Object.keys(selector).length === 0) { throw new Error('in queries delete, empty selector'); }
    const result = await API.del(server, basePath, {
      body: { projection, options, selector },
    });
    return result;
  } catch (error) {
    throw error;
  }
}
