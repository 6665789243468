import React, { Component } from "react";
import { Cache } from "aws-amplify";
import { currentAuthenticatedUser, currentUserInfo, federatedSignIn } from '../AWS/wrapper';
import LoaderButton from "./LoaderButton";

function waitForInit() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}

export default class FacebookButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    await waitForInit();
    this.setState({ isLoading: false });
  }

  statusChangeCallback = response => {
    console.log('In FacebookButton.statusChangeCallback, Facebook Response: ', response);
    if (response.status === "connected") {
      this.handleResponse(response.authResponse);
    } else {
      this.handleError(response);
    }
  };

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };

  handleClick = () => {
    window.FB.login(this.checkLoginState, {scope: 'email,user_likes', return_scopes: true});
  };

  handleError(error) {
    console.log('Facebook button error', error);
    alert(error);
  }

  async handleResponse(data) {
    console.log('Incoming Facebook response: ', data);

    window.FB.api('/me', (response) => {
      console.log('Facebook api response: ', response);
    });
    const { email, accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();
    const user = email ? { email } : {};

    this.setState({ isLoading: true });

    try {
      const response = await federatedSignIn("facebook", { token, expires_at }, user);
      console.log('Response from federatedSignIn', response);
      const info = await Cache.getItem('federatedInfo');
      console.log('Facebook Cache info.token' , info.token);
      const authUser = await currentAuthenticatedUser()
      console.log('AuthUser:', authUser);
      const ui = await currentUserInfo();
      console.log('CurrentUserInfo', ui);
      //console.log(new Amplify.CognitoIdentityCredentials({
      //  IdentityPoolId: 'us-west-2:f982bdf3-34ee-4837-9166-434eb8259321',
      //}));
      // console.log(response.sessionToken);
      // const session = await Auth.currentAuthenticatedUser();
      // console.log('currentAuthenticatedUser: ', session);
      // const id = await Auth.currentUserInfo();
      // console.log('identity', id);
      this.setState({ isLoading: false });
      this.props.onLogin(response);
    } catch (e) {
      this.setState({ isLoading: false });
      this.handleError(e);
    }
  }

  render() {
    return (
      <LoaderButton
        block
        bsSize="large"
        bsStyle="primary"
        className="FacebookButton"
        text="Login with Facebook"
        onClick={this.handleClick}
        disabled={this.state.isLoading}
      />
    );
  }
}
