import { uuid } from '../Models/Enums/modelFields';
import { limit, skip } from '../Models/Enums/comparison';

const MAX_SIZE = 1000;
const DEFAULT_LIMIT = 10;

class ListCache {
  constructor(elementCache) {
    this.elementCache = elementCache
    this.collapsed = false;
    this.array = [];
    this.offset = 0;
    this.query = {};
    this.loading = false;
    this.cancel = false;
    this.limit = DEFAULT_LIMIT;
    this.end = false;
  }
  numberOfElements() {
    if (this.collapsed) { return 0; }
    return this.array.length + this.offset;
  }
  async getElementByIndex(index) {
    if (this.collapsed) { return null; }
    const physicalIndex = index - this.offset;
    if ((physicalIndex < 0) || (physicalIndex >= this.array.length)) { return null; }
    const element = await this.elementCache.getElementByUUID(this.array[physicalIndex]);
    return element;
  }
  async getElementByUUID(uuid) {
    if (this.collapsed) { return null; }
    if (typeof uuid !== typeof '') {
      console.log('In getElementByUUID, uuid is not a string', uuid);
      return null;
    }
    const element = await this.elementCache.getElementByUUID(uuid);
    return element;
  }
  async updateElement(element) {
    const result = await this.elementCache.updateElement(element);
    return result;
  }
  async deleteElement(element) {
    if ((element !== null) && (element[uuid] !== null)) {
      const result = await this.elementCache.deleteElementByUUID(element);
      return result;
    } else {
      console.log('Element or UUID is null', element);
      return null;
    }
  }
  async load(reset = false) {
    if (this.loading) { return null; }
    this.loading = true;
    if (reset) {
      this.array = [];
      this.offset = 0;
      this.end = false;
    }
    if (this.array.length > MAX_SIZE) { return null;}
    let { selector, projection, options } = this.query;
    selector = selector || {};
    projection = projection || {};
    options = options || {};
    options[skip] = (reset) ? 0 : this.numberOfElements();
    options[limit] = this.limit;
    try {
      const result = await this.elementCache.model.list({ options, projection, selector });
      const copy = (reset) ? result : this.array.concat(result);
      if (!this.cancel) { this.array = copy; }
      this.cancel = false;
      if (result.length === 0) { this.end = true;}
      this.loading = false;
      return copy;
    } catch (error) {
      console.log('In ListCache.load, got error ', error);
      throw error;
    }
  }
  isEnd() {
    return this.end;
  }
  reset() {
    this.cancel = true;
    this.collapse = false;
    this.array = [];
    this.offset = 0;
    this.loading = false;
    this.end = false;
  }
}

export default ListCache;
