import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;
  console.log('s3Upload', filename, file.type);
  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });
  console.log('Store result', stored);
  return stored.key;
}
