import React, { Component } from 'react';
import ListCache from '../Cache/ListCache';
import ElementCache from '../Cache/ElementCache';
import Interest from '../Models/Interest';
import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteListElementTest from './InfiniteListElementTest';
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};
export default class InfiniteScrollTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loadingList: false,
    };
    this.listCache = new ListCache( new ElementCache( new Interest()));
  }
  componentDidMount() {
    this.fetchMoreData();
  }
  fetchMoreData = async (reset = false) => {
    console.log('In fetchMoreData');
    if (this.state.loadingList) { return; }
    this.setState({ loadingList: true })
    const items = await this.listCache.load(reset);
    this.setState({ items, loadingList: false });
    /*
    setTimeout(() => {
      const items = reset ? Array.from({ length: 20 }) : this.state.items.concat(Array.from({ length: 20 }))
      this.setState({
        items,
      })
    });
    */
  }
  hasMore = () => {
    console.log('Has more', !this.listCache.isEnd());
    return !this.listCache.isEnd();
    //if (this.state.items.length > 50) { return false; }
    // return true;
  }
  refresh = () => {
    this.fetchMoreData(true);
  }
  render() {
    const { items } = this.state;
    return(<InfiniteScroll
      dataLength={items.length}
      next={this.fetchMoreData}
      loader={<h4>Loading...</h4>}
      hasMore={this.hasMore()}
      endMessage={
        <p style={{textAlign: 'center'}}>
          <b>Yay! You have seen it all</b>
        </p>
      }
      refreshFunction={this.refresh}
      pullDownToRefresh
      pullDownToRefreshContent={
        <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
      }
      releaseToRefreshContet={
        <h3 style={{textAlign: 'center'}}>&#8593; Release to refresn</h3>
      }
      >
        {items.map((uuid,index) => (
            <InfiniteListElementTest key={index} uuid={uuid} listCache={this.listCache} />
        ))}
    </InfiniteScroll>
    );
  }
}
