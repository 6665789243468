import Amplify, { API, Auth, Cache, Storage } from 'aws-amplify';
import config from '../config';
// import testrun from './testrun';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "notes",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ]
  }
});
loadFacebookSDK();

export async function authorization(cognito = true){
  console.log('In start of Authorization');
  if (cognito) {
    const session = await Auth.currentSession();
    if (session) {
      if (session.idToken) {
        return session.idToken.jwtToken;
      } else {
        console.log('In Authorization no idToken');
      }
    } else {
      console.log('In Authorization, no session');
      return null;
    }
  }
  const info = await Cache.getItem('federatedInfo');
  console.log('FederatedInfo is:', info);
  if (info !== null ) {
    return info.token;
  }
  console.log('In Authorization, have federatedInfo but no token', info);
  return null;
}


export async function currentAuthenticatedUser() {
  console.log('In currentAuthenticatedUser');
  return await Auth.currentAuthenticatedUser();
}
export async function currentSession() {
  console.log('In currentSession');
  return await Auth.currentSession();
}
export async function currentUserInfo(){
    console.log('In currentUserInfo');
    return await Auth.currentUserInfo();
}

export async function signIn(email, password) {
  console.log('In signin with', email, password);
  return await Auth.signIn(email, password);
}
export async function updateUserAttributes(updates) {
  const user = await currentAuthenticatedUser();
  if (user) {
    return await Auth.updateUserAttributes(user, updates);
  } else {
    console.log('In updateUserAttributes no user');
    throw new Error('No currentAuthenticatedUser');
  }
}
export async function changePassword(oldPassword, newPassword) {
  console.log('In changePassword', oldPassword, newPassword);
  const user = await currentAuthenticatedUser();
  if (user) {
    return await Auth.changePassword(user, oldPassword, newPassword);
  } else {
    console.log('In changePassword no user');
    throw new Error('No changePassword');
  }
}
export async function forgotPassword(email) {
  console.log('In forgotPassword', email);
  return await Auth.forgotPassword(email);
}
export async function forgotPasswordSubmit(email, code, password) {
  console.log('In forgotPasswordSubmit', email, code, password);
  return await Auth.forgotPasswordSubmit(email, code, password);
}
export async function federatedSignIn(provider, info, user) {
  console.log('In federatedSignin, Provider:', provider, '\nInfo:', info, '\nUser:', user);
  return await Auth.federatedSignIn(provider, info, user);
}
export async function verifyCurrentUserAttributeSubmit(term, code) {
  console.log('verifyCurrentUserAttrributeSubmit', term, code);
  return await Auth.verifyCurrentUserAttributeSubmit(term, code);
}
export async function signOut() {
  console.log('In signout');
  return await Auth.signOut();
}
export async function post(server, path, params) {
  console.log('In Post', server, path, params);
  return await API.post(server, path, params);
}
// https://aws-amplify.github.io/amplify-js/media/api_guide
/*
let myInit = { // OPTIONAL
    headers: {} // OPTIONAL
    response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        name: 'param'
    }
}
*/
export async function get(server, path, init) {
  console.log('In get input is:', server, path, init);
  try {
    // testrun();
    //const server = 'notes';
    // const path = '/basic/interest/c3c48e90-d33b-11e8-8be5-bbd4194e6d1a/';
    /*
    const path = '/basic/interest/';
    //const selector = {uuid: {$eq: 'fudd'}};
    const selector = {};
    //const projection = {};
    const options = {sort: {createdAt: -1}};
    const result = await API.get(server, path, {
      queryStringParameters: {
        selector: JSON.stringify(selector),
        options: JSON.stringify(options),
      },
      // body: { selector, options },
    });
    console.log('Get result: ', result);
    const post = await API.post(server, '/basic/interest/', {
      body: { description: 'Later description'},
    });

    console.log('Post result: ', post);
    */
  } catch(error) {
    console.log('Got error on ', path, error);
  }
  return await API.get(server, path, init);
}
export async function put(server, path, params) {
  console.log('In Put', server, path, params);
  return await API.put(server, path, params);
}
export async function del(server, path) {
  console.log('In Delete', server, path);
  return await API.del(server, path);
}
export async function getStorage(attachment) {
  console.log('In getStorage', attachment);
  return await Storage.vault.get(attachment);
}

async function loadFacebookSDK() {
  window.fbAsyncInit = function() {
    window.FB.init({
      appId            : config.social.FB,
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v3.1'
    });
  };
  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
}
