import queries, { commands } from '../AWS/queries';
import { uuid } from './Enums/modelFields';

const DEFAULT_SERVER = 'notes';
const DEFAULT_BASE = 'basic';

class BaseModel {
  constructor(modelName = 'BaseModel', server = DEFAULT_SERVER, base = DEFAULT_BASE) {
    this.modelName = modelName;
    this.server = server;
    this.base = base;
    this.doc = null;
    this.updateDoc = {};
  }
  async getOne(uuid, params = {}) {
    let { options, projection } = params;
    const result = await queries({
      command: commands.get,
      modelName: this.modelName.toLowerCase(),
      uuid,
      options: options || {},
      projection: projection || {},
    }, this.server, this.base);
    return result;
  }
  async list(params = {}) {
    let { options, projection } = params;
    const result = await queries({
      command: commands.list,
      modelName: this.modelName.toLowerCase(),
      options: options || {},
      projection: projection || {},
    }, this.server, this.base);
    return result;
  }
  async create(doc, params = {}) {
    let { options, projection } = params;
    const result = await queries({
      command: commands.create,
      modelName: this.modelName.toLowerCase(),
      doc,
      options: options || {},
      projection: projection || {},
    }, this.server, this.base);
    return result;
  }
  async update(doc, selector = {}) {
    const result = await queries({
      command: commands.update,
      modelName: this.modelName.toLowerCase(),
      doc,
      selector
    }, this.server, this.base);
    return result;
  }
  async updateSelf() {
    if (Object.keys(this.udpateDoc).length === 0) {
      console.log('In updateSelf, nothing to update');
      return null;
    }
    if (typeof this.doc[uuid] !== typeof '') {
      console.log('In .updateSelf, invalid uuid', uuid);
      return null;
    }
    const selector = { [uuid]: this.doc.uuid }
    const result = this.update(this.updateDoc, selector);
    this.updateDoc = {};
    this.doc = result;
    return result;
  }
  async remove(selector = {}) {
    const result = await queries({
      command: commands.remove,
      modelName: this.modelName.toLowerCase(),
      selector
    }, this.server, this.base);
    this.doc = null;
    this.updateDoc = {};
    return result;
  }
  innerFieldUpdate(field, newValue) {
    this.doc[field] = newValue;
    this.updateDoc[field] = newValue;
    return newValue
  }
  anyUpdatedFields() {
    if (Object.keys(this.updateDoc).length > 0) { return true; }
    return false;
  }
  updateField(field, newValue) {
    if (typeof field !== typeof '') {
      console.log('In updateField, field is not a string', field);
      return null;
    }
    if (newValue === undefined) {
      console.log('In updateField, newValue is undefined');
      return null;
    }
    const existingValue = this.doc[field];
    if ( existingValue === undefined) {
      if ((newValue === undefined ) || (newValue === null)) { return null;  }
      return this.innerFieldUpdate(field, newValue);
    } else if (existingValue === null) {
      if (newValue === null) { return null; }
      return this.innerFieldUpdate(field, newValue);
    } else if (existingValue === newValue) {
        return newValue;
    }
    return this.innerFieldUpdate(field, newValue);
  }

}
export default BaseModel
