const dev = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "notes181007-dev-serverlessdeploymentbucket-is0rlspusowm"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://q1pmsk9v1g.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_JOfQL1nFI",
    APP_CLIENT_ID: "3n302ms2e8hn8v329c2hqhti0g",
    IDENTITY_POOL_ID: "us-west-2:b4832da2-b09a-44f4-9d10-2e6638a80697"
  },
  STRIPE_KEY: "pk_test_prUsNzYBVUn1CqDSYfCVRBv2",
  social: {
    FB: '275381139973095',
  },
};

const prod = {
  s3: {
    REGION: "us-west-2",
    BUCKET: " notes181007-prod-serverlessdeploymentbucket-14tzg28s3bi7g"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://7spu8j5vv0.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_8uHEOVjMz",
    APP_CLIENT_ID: "1sk5l94ai8f8m8pi5ufdhmulh6",
    IDENTITY_POOL_ID: "us-west-2:90ea00de-0553-4197-a5a8-c38ac0d685ae"
  },
  STRIPE_KEY: "pk_test_prUsNzYBVUn1CqDSYfCVRBv2",
  social: {
    FB: 'need to get',
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
