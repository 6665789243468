import React, { Component } from 'react';
import PropTypes from 'prop-types';

const style = {
  height: 20,
  border: "1px solid red",
  margin: 10,
  padding: 18
};
export default class InfiniteListElementTest extends Component {
  state = {
    element: null,
    loading: false,
  }
  componentDidMount() {
    this.getElement();
  }
  componentDidUpdate() {
    console.log('In componentDidUpdate');
    this.getElement();
  }
  async getElement() {
    // console.log('In getElement', uuid, listCache);
    const { uuid, listCache } = this.props;
    if ((uuid === undefined) || (uuid === null)) { console.log('uuid undefined'); }
    if ((listCache === undefined) || (listCache === null)) { console.log('listCache undefined'); }
    if ((typeof uuid !== typeof '') || (listCache === undefined)) { return; }
    const { element } = this.state;

    if ((element === null ) || ((element !== null) && (element.uuid !== uuid) )) {
      if (this.state.loading) { return }
      this.setState({ loading: true });
      // console.log('ListCacahe', listCache);
      const newElement = await listCache.getElementByUUID(uuid);
      this.setState({ element: newElement, loading: false});
    }
  }
  render() {
    const { element } = this.state;
    const { uuid } = this.props;
    if ((element !== undefined) && (element !== null)) {
      return (
        <div style={style}>{element.uuid}</div>

      );
    }
    return (<div style={style} >No Element {uuid} </div>);
  }

}
InfiniteListElementTest.propTypes = {
  uuid: PropTypes.string.isRequired,
  listCache: PropTypes.object.isRequired,
};
